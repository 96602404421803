import React from 'react';
import logo from './assets/logo.png';
import {Box, Container, Typography} from "@mui/material";
import {UploaderForm} from "./components/UploaderForm";

function App() {
  return (
      <Container>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <img src={logo} className="App-logo" alt="logo" height={80}/>
        </Box>
        <Box display='flex' justifyContent='center' >
            <Typography variant='h6'>ioTec Face Verification</Typography>
        </Box>
        <Box pt={2}>
          <UploaderForm/>
        </Box>
      </Container>
  );
}

export default App;
