import {isDate} from "date-fns";
import {isNumber,isEmpty} from "lodash";

export const hasNoValue = (text: any) => {
    if (isDate(text)) return false;
    if (isNumber(text)) return false;
    return isEmpty(text);
};

export const hasValue = (text: any) => !hasNoValue(text);
