import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, {useState} from "react";
import {Box} from "@mui/material";

import {grey} from "@mui/material/colors";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

type Props = {
    title?: string;
    defaultImage: string;
    maxHeight: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ImagePicker({title, onChange, defaultImage, maxHeight}: Props) {

    const [preview, setPreview] = useState<string | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        onChange?.(event);
        if (file) {
            setPreview(URL.createObjectURL(file));
        } else {
            setPreview(null);
        }
    };
    return (
        <Box>

            <Box display='flex' flexDirection='column' sx={{border:`1px solid ${grey[300]}`, borderRadius:8, p:2}}>
                <Box display='flex' justifyContent='center' p={1}>
                    <img src={preview ?? defaultImage} style={{maxHeight}} alt="Preview"/>
                </Box>

                <Box display='flex' justifyContent='center'>
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        color='inherit'
                        sx={{textTransform: 'none'}}
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon/>}
                    >
                        {title}
                        <VisuallyHiddenInput
                            type="file"
                            onChange={handleImageChange}
                            multiple
                        />
                    </Button>
                </Box>
            </Box>
        </Box>

    );
}
