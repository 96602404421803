
import {hasValue} from "./input-utils";


export interface IClientError {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: any;
}

const getValidationError = (data: any): string|null => {
  const error: IClientError = data;
  if (hasValue(error.title) && error.title.indexOf('validation errors') > -1) {
    let { title } = error;
    Object.entries(error.errors).forEach((it) => {
      const [, value] = it;
      title = `${title}\n${value}`;
    });
    return title;
  }
  return null;
};

export function serialize(obj:any, prefix?:string) {
  const str:string[] = [];
  Object.entries(obj).filter((it) => {
    const [, v] = it;
    return hasValue(v);
  }).forEach((e:any) => {
    const [p, v] = e;
    const k = prefix ? `${prefix}[${p}]` : p;
    str.push((v !== null && typeof v === 'object')
        ? serialize(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
  });
  return str.join('&');
}

export const handleError = async (res: Response): Promise<any|null> => {
  const defaultMessage = 'Failed to load data, check connectivity.';

  if (res.status === 403) {
    throw Error('You don\'t have permission for this page!');
  }
  if (res.status === 401) {
    // clearAuthToken();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    throw Error('You need to login to see this page!');
  }
  if (res.status === 400) {
    const validationError = getValidationError(res);
    if (hasValue(validationError)) {
      throw Error(validationError??defaultMessage);
    }
    const { message, errors = [] } = await res.json();
    let msg = `${message}\n`;

    errors.forEach((it: ArrayLike<unknown> | { [s: string]: unknown; }) => {
      const error = Object.values(it)[0];
      msg += (`${error}\n`);
    });
    throw Error(msg || defaultMessage);
  }
  if (res.status === 404) {
    const { message } = await res.json();
    throw Error("Resource not found: " + message);
  }
  throw Error("Can't reach server, Check connectivity!");
};


export async function postFormData(url = '', data: FormData) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: data
  });
  if (!response.ok) {
    return handleError(response);
  }
  return await response.json();
}
