import React from 'react';
import Button from '@mui/material/Button';
import {Alert, Box, Grid} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import ImagePicker from "./ImagePicker";
import selfieBase from '../assets/selfie_frame.png';
import idBase from '../assets/ug_id_card_front.png';
import {postFormData} from "../utils/fetch-helpers";
import {apiUrl} from "../constants";


export function UploaderForm() {
    const [idFront, setIdFront] = React.useState<FileList | null>(null);
    const [passport, setPassport] = React.useState<FileList | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);
    const [message, setMessage] = React.useState<string | null>(null);

    async function handleVerify() {
        if (idFront === null || passport === null) {
            setError('Please upload both files');
            return
        }
        setError(null);
        setMessage(null);
        const formData = new FormData();
        formData.append('file1', idFront[0]);
        formData.append('file2', passport[0]);

        setLoading(true);
        try {
            const response = await postFormData(`${apiUrl}/process`, formData)
            if (response.match === "True") {
                setMessage('The face on the passport photo and the ID-Front photo match');
                setError(null);
            } else {
                setError('The face on the passport photo and the ID-Front photo do not match');
                setMessage(null);
            }
            console.log(response);
        } catch (e) {
            console.log(e);
            setError('We were unable to verify the photos');
            setMessage(null);
        } finally {
            setLoading(false);
        }

    }

    return (
        <>
            <Grid container spacing={3}>

                {
                    message && <Grid item xs={12}>
                        <Alert severity='success'>{message}</Alert>
                    </Grid>
                }
                {
                    error && <Grid item xs={12}>
                        <Alert severity='error'>{error}</Alert>
                    </Grid>
                }
                <Grid item xs={12}>
                    <ImagePicker
                        maxHeight={100}
                        defaultImage={selfieBase}
                        title="Upload current passport photo"
                        onChange={(event) => setIdFront(event.target.files)}
                    />

                </Grid>
                <Grid item xs={12}>
                    <ImagePicker
                        maxHeight={100}
                        defaultImage={idBase}
                        title="Upload ID-Front photo"
                        onChange={(event) => setPassport(event.target.files)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent='center'>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            sx={{backgroundColor: '#1E5AE3'}}
                            size={'large'}
                            onClick={handleVerify}
                            disabled={loading}
                        >Verify</Button>
                    </Box>
                    {
                        loading && <Box display="flex" justifyContent='center'>
                            <CircularProgress />
                        </Box>
                    }
                </Grid>
            </Grid>

        </>
    );
}


